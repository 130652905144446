import React from 'react';

const BCrumbs = props => (
  <nav className="bcrumbs">
    <ul>
      <li>
        <a href="/">Home</a>
      </li>
      {props.crumbs &&
        !!props.crumbs.length &&
        props.crumbs.map(crumb => (
          <li>
            <a href={crumb.path}>{crumb.title}</a>
          </li>
        ))}
    </ul>
  </nav>
)

export default BCrumbs
