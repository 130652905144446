import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'

import Layout from '../components/layout'
import BCrumbs from '../components/BCrumbs'
import HeaderGeneric from '../components/HeaderGeneric'

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark
  const { title, tags, authors, date, author, banner } = frontmatter
  const blogTitle = 'Digital Tumbleweed: Blog'

  return (
    <Layout>
      <Helmet title={`${blogTitle}: ${title}`} />

      <div id="main">
        <img src={banner.childImageSharp.fluid.src} alt="" className="header" />

        <BCrumbs crumbs={[{ path: '/blog', title: 'Blog' }]} />

        <HeaderGeneric title={title}>
          {tags.find(tag => tag === 'book') && (
            <div className="authors">
              Authors:
              <br />
              {authors.map(author => (
                <div>
                  {author}
                  <br />
                </div>
              ))}
            </div>
          )}
        </HeaderGeneric>

        <section id="content" className="main">
          <header className="post-header">
            <span>{date}</span>
            <span>{author}</span>
          </header>

          <div
            className="blog-post-content"
            dangerouslySetInnerHTML={{ __html: html }}
          />
        </section>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
        author
        tags
        authors
        banner {
          childImageSharp {
            fluid(maxWidth: 1000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
